import React from "react"
// import { Link } from "gatsby"

import UserLayout from "../components/userLayout"
import SEO from "../components/seo"
// import { Button } from "bootstrap"

export default () => (
  <UserLayout>
    <SEO
      title="D2i Technology is the Best DevOps Service(s) Company in USA / UK "
      description="D2i Technology is the best DevOps services company in USA/UK. Software Company for you With our initiation, we helped many organizations all over the world to derive the benefits from the best DevOps companies strategy and save time & cost."
    />    <h2 className="holidayHeading"> Upcoming Holidays</h2>

    <div className="holidayContainer" >

  <table className="holidayTable">
    <tr>
      <th>Date</th>
      <th>Day</th>
      <th>Month</th>
      <th>Category</th>
      <th className="max" colspan="2">Holiday</th>
    </tr>
    <tr>
      <td>11</td>
      <td>Thursday</td>
      <td>August</td>
      <td>Optional</td>
      <td className="max" colspan="2">Raksha Bandhan</td>
    </tr>

    <tr>
      <td>15</td>
      <td>Monday</td>
      <td>August</td>
      <td>Mandatory</td>
      <td className="max" colspan="2">Independence Day</td>
    </tr>
    <tr>
      <td>02</td>
      <td>Sunday</td>
      <td>October</td>
      <td>Mandatory</td>
      <td className="max" colspan="2">Gandhi Jayanti</td>
    </tr>
    <tr>
      <td>05</td>
      <td>Wednesday</td>
      <td>October</td>
      <td>Mandatory</td>
      <td className="max" colspan="2">Vijya Dashmi</td>
    </tr>

    <tr>
      <td>24</td>
      <td>Monday</td>
      <td>October</td>
      <td>Mandatory</td>
      <td className="max" colspan="2">Diwali</td>
    </tr>
    <tr>
      <td>16</td>
      <td>Sunday</td>
      <td>October</td>
      <td>Optional</td>
      <td className="max" colspan="2">Bhaiya Dooj</td>
    </tr>
    <tr>
      <td>25</td>
      <td>Sunday</td>
      <td>December</td>
      <td>Optional</td>
      <td className="max" colspan="2">Christmas</td>
    </tr>
    <tr>
      <td>01</td>
      <td>Sunday</td>
      <td>January</td>
      <td>Optional</td>
      <td  className="max" colspan="2">New Year</td>
    </tr>  
  </table>
  </div>

  
    <div className="rightSide">
      <h4>Holiday Plan</h4>

      <div class="innerContainer">
       
  <div><center>3 National Holidays</center></div>
  
  <div>Holi</div>
  
  <div>Diwali</div> 
  
  <div>Dussehra</div>
  
  <div><center>3 Optional Holidays</center></div>
  
 
  <div className="result"> Total 9 Holidays</div> 

  


      </div>

    </div>


    <div className="earnedLeave">
      <fieldset>
        <legend>Earned Leave</legend>
      Every montth candidate/employee earn 1 leave  and that he/she  can take  for any purpose. But make sure you inform  your manager/lead/POC atleast 2 days prior to any leave  incase of sickness  you  can take  leave  on the spot  but make sure you  inform your POC  and teammates.
      </fieldset>

    </div>
  


  </UserLayout>
)
